import React from "react"
import { graphql } from "gatsby"
// import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import hiringStyles from "./hiring.module.css"
// import chairImage from "../images/chair-contactpage.png"

const HiringPage = ({ data }) => (
    <Layout>
        <SEO title="Hiring" />
        <div className={hiringStyles.backgroundColor}></div>
        <div className={hiringStyles.hiringSection}>
            <p>Stodgy Brewing Co is not currently hiring. Follow us on social media to find out when we're hiring again next.</p>
            {/* <h1>Stodgy Brewing Company is Hiring a Part-Time Bartender!</h1>
            <p>Join our small team of dedicated beer enthusiasts. We're looking for folks that would 
                love a part-time bartending job. Service industry or customer service experience is a 
                big plus. You must be able to be quick, efficient, like beer and be willing to learn 
                lots about it, and have a flexible schedule. All applicants must be over 21.</p>
            <p>To apply, please come in person between 1pm and 7pm Monday-Thursday.  Bring your 1 page resume and a short cover letter that answers the following questions:</p>
            <ol>
                <li>Why do you want to work at Stodgy (besides money)?</li>
                <li>What excites you in life (besides beer)?</li>
                <li>What's your weekly availability?</li>
            </ol> */}
        </div>
    </Layout>
)

export const query = graphql`
  query {
    beerWordImage: file(relativePath: { eq: "beer_word.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default HiringPage
